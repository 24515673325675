const mfa = {
    title: '多重因素驗證',
    description: '添加多重因素驗證以提升登入體驗的安全性。',
    factors: '因素',
    multi_factors: '多重因素',
    multi_factors_description: '用戶需要驗證其中一個已啟用的因素以完成兩步驗證。',
    totp: '驗證器應用程式 OTP',
    otp_description: '連結 Google Authenticator 等應用程式，以驗證一次性密碼。',
    webauthn: 'WebAuthn（通行密鑰）',
    webauthn_description: '透過瀏覽器支援的方式進行驗證：生物識別、手機掃描或安全密鑰等。',
    webauthn_native_tip: '原生應用程式不支援 WebAuthn。',
    webauthn_domain_tip: 'WebAuthn 會將公開密鑰綁定至特定網域。變更服務網域將導致用戶無法使用現有通行密鑰進行驗證。',
    backup_code: '備用代碼',
    backup_code_description: '在用戶設定任何 MFA 方法後生成 10 個一次性備用代碼。',
    backup_code_setup_hint: '當用戶無法驗證上述 MFA 因素時，可使用備用選項。',
    backup_code_error_hint: '要使用備用代碼，你需要至少再添加一種 MFA 方法以完成用戶驗證。',
    policy: '政策',
    policy_description: '設定登入和註冊流程的 MFA 政策。',
    two_step_sign_in_policy: '登入時的雙重驗證政策',
    user_controlled: '用戶可自行啟用或停用 MFA',
    user_controlled_tip: '用戶可在首次登入或註冊時跳過 MFA 設定，或在帳戶設定中啟用/停用它。',
    mandatory: '用戶必須在登入時使用 MFA',
    mandatory_tip: '用戶必須在首次登入或註冊時設定 MFA，並在之後的所有登入中使用。',
    require_mfa: '需要 MFA',
    require_mfa_label: '啟用此選項可將雙重驗證設為存取應用程式的強制要求。若停用，用戶可自行決定是否啟用 MFA。',
    set_up_prompt: 'MFA 設定提示',
    no_prompt: '不要求用戶設定 MFA',
    prompt_at_sign_in_and_sign_up: '在註冊期間要求用戶設定 MFA（可跳過的一次性提示）',
    prompt_only_at_sign_in: '在註冊後的下次登入時要求用戶設定 MFA（可跳過的一次性提示）',
    set_up_organization_required_mfa_prompt: '組織啟用 MFA 後要求用戶設定的 MFA 提示',
    prompt_at_sign_in_no_skip: '在下次登入時要求用戶設定 MFA（不可跳過）',
};
export default Object.freeze(mfa);
